<template>
  <main class="main page__view">
    <teleport to="head">
      <title v-if="$route.params.productsCategorie != 'any'">
        Категория {{ productCategorie }} . СК "Ореховском"
      </title>
      <title v-else>
        Интернет магазин завода по производству тротуарной плитки
        "Ореховском"
      </title>
    </teleport>
    <ul class="products__types">
      <li
        class="products__type"
        :key="productsType.value"
        v-for="productsType in productsTypes"
      >
        <router-link
          :to="
            `/products/${$route.params.productsCategorie}/${productsType.value}`
          "
          class="products__type__link"
          active-class="products__type__link--active"
          :exact-path="true"
        >
          {{ productsType.label }}
        </router-link>
      </li>
    </ul>
    <Hr />
    <ul class="products__list">
      <li class="products__item" v-for="product of products" :key="product.id">
        <router-link
          :to="'/product/' + product.id"
          class="products__item__body products__item__link"
        >
          <img
            :src="product.images[0]"
            :alt="`${product.name}    изображение`"
            class="products__item__image"
          />
          <p class="products__item__name">{{ product.name }}</p>
          <p class="products__item__info">{{ product.info }}</p>
          <div class="products__item__prices">
            <p>{{ product.prices[0] }}</p>
            <p>{{ product.prices[1] }}</p>
          </div>
        </router-link>
      </li>
    </ul>
    <p class="notProducs" v-if="!products.length">
      Продукты не найдены
    </p>
  </main>
</template>

<script>
// import ProductCardList from '@/components/ProductCardList.vue';
import Hr from '@/components/Hr.vue';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      productsTypes: [
        { value: 'any', label: 'Показать все' },
        { value: 'vibropress', label: 'Вибропресс' },
        { value: 'vibroliteo', label: 'Вибролитье' },
        { value: 'bordjurnyj-kamen', label: 'Бордюрный камень' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getProductsByCategorieAndType',
      'getProductsCategorieByValue',
    ]),
    products() {
      return this.getProductsByCategorieAndType(
        this.$route.params.productsCategorie,
        this.$route.params.productsType
      );
    },
    productCategorie() {
      const { productsCategorie } = this.$route.params;
      const productCategorieLabel = this.getProductsCategorieByValue(
        productsCategorie
      ).label;
      return productCategorieLabel;
    },
  },
  components: { Hr },
};
</script>

<style scoped>
/* Products Types */
.products__types {
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
  list-style-type: none;
  flex-wrap: wrap;
}
/* Products Type Link */
.products__type__link {
  display: inline-block;
  padding: 10px 15px;
  color: var(--primary-color);
  background: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
}
/* Active Products Type Link */
.products__type__link--active {
  background-color: var(--primary-color);
  color: white;
}
.products__type__link:hover:not(.products__type__link--active) {
  background-color: #eee;
  color: var(--primary-color);
}
/* Products */
.products {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
  row-gap: 40px;
}
@media (max-width: 1000px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 730px) {
  .products {
    grid-template-columns: repeat(1, 1fr);
  }
}

.products__list {
  display: grid;
  row-gap: 20px;
  list-style: none;
}

.products__item__body {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 20px 0;
  text-decoration: none;
  color: inherit;
}

@media (max-width: 880px) {
  .products__item__body {
    grid-auto-flow: row;
    row-gap: 20px;
    text-align: center;
  }
}

.products__item:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.products__item__image {
  width: 100px;
}

@media (max-width: 880px) {
  .products__item__image {
    width: 40%;
    justify-self: center;
  }
}

.notProducs {
  padding: 20px 0;
}
</style>
